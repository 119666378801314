<template>
  <b-modal
    id="department-modal"
    :title="
      readonly
        ? 'عرض بيانات الفرع'
        : form.id
        ? 'تحديث بيانات الفرع'
        : 'أضافة فرع جديد'
    "
    shadow
    hide-footer
    :no-close-on-backdrop="true"
    @hidden="clearForm"
    @show="initialPosition"
    size="lg"
    body-class="p-5"
  >
    <VForm v-slot="{ onValidate }">
      <b-row>
        <b-col>
          <VTextField
            v-model="form.name"
            label="أسم الفرع"
            rules="required"
            :readonly="readonly"
          ></VTextField
        ></b-col>
        <b-col>
          <VTextField
            v-model="form.phone"
            label="رقم الهاتف"
            rules="required"
            :readonly="readonly"
          ></VTextField
        ></b-col>
      </b-row>
      <b-row>
        <b-col>
          <VSelectField
            v-model="form.governate"
            :items="governates"
            label="المحافظة"
            rules="required"
            :readonly="readonly"
          ></VSelectField
        ></b-col>
        <b-col
          ><VTextField
            v-model="form.address"
            label="العنوان"
            rules="required"
            :readonly="readonly"
          ></VTextField
        ></b-col>
      </b-row>

      <b-form-checkbox dir="rtl" switch v-model="addLocation" size="sm" 
        >
        <span :class="'px-5'">هل تريد أضافة الموقع ؟</span>
        </b-form-checkbox>
    
      <div
        v-if="addLocation"
        class="my-3 h-100"
        style="display: contents"
      >
      <b-row class="align-items-center">
        <b-col>
          <VTextField
            v-model="form.lat"
            label="خطوط العرض"
            :readonly="readonly"
          ></VTextField
        ></b-col>
        <b-col>
          <VTextField
            v-model="form.lng"
            label="خطوط الطول"
            rules="required"
            :readonly="readonly"
          ></VTextField
        ></b-col>
      </b-row>
      <span>أو تحديد الموقع على الخريطة</span>
        <gmap-map
          :zoom="14"
          :center="{ lat: parseFloat(form.lat), lng: parseFloat(form.lng) }"
          style="width: 100%; height: 400px"
        >
          <gmap-marker
            :position="{ lat: parseFloat(form.lat), lng: parseFloat(form.lng) }"
            :clickable="true"
            :draggable="true"
            :icon="markerOptions"
            @dragend="setLocation"
          ></gmap-marker>
        </gmap-map>
      </div>

      <b-row class="my-3" v-if="!readonly">
        <b-col>
          <b-button
            :variant="form.id ? 'success' : 'info'"
            block
            @click="onValidate(action)"
            >{{ form.id ? "تحديث" : "أضافة" }}</b-button
          >
        </b-col>
        <b-col>
          <b-button
            variant="outline-info"
            block
            @click="$bvModal.hide('department-modal')"
            >الغاء</b-button
          >
        </b-col>
      </b-row>
    </VForm>
  </b-modal>
</template>

<script>
import { clearObject, successAlert, errorAlert } from "@/utils/helper";

export default {
  props: { readonly: Boolean },
  data() {
    return {
      addLocation: true,
      markerOptions: {
        url: require("../../../../assets/marker.png"),
        size: { width: 30, height: 60, f: "px", b: "px" },
        scaledSize: { width: 25, height: 45, f: "px", b: "px" },
      },
    };
  },
  computed: {
    form: {
      get() {
        return this.$store.state.banks.branches.form;
      },
      set(value) {
        this.$store.commit("banks/branches/SET_BRANCH", value);
      },
    },
    governates() {
      return this.$store.state.banks.branches.governates;
    },
  },
  methods: {
    action() {
      var state = this.form.id ? "update" : "store";
      if(!this.addLocation) {
        this.form.lat = 0;
        this.form.lng = 0;
      }
      this.$store
        .dispatch(`banks/branches/${state}`)
        .then((response) => {
          successAlert();
          this.$bvModal.hide("department-modal");
          this.$store.commit(
            `banks/branches/${state.toUpperCase()}_BRANCH`,
            response.payload
          );
        })
        .catch((error) =>
          errorAlert(
            error.response ? error.response.data.message : "حدثت مشكله"
          )
        );
    },
    // locateGeoLocation() {
    //   navigator.geolocation.getCurrentPosition((res) => {
    //     this.form.lat = res.coords.latitude;
    //     this.form.lng = res.coords.longitude;
    //   });
    // },
    setLocation(data) {
      this.form.lat = data.latLng.lat();
      this.form.lng = data.latLng.lng();
    },
    // setLocationMan(lat, lng) {
    //   this.form.lat = lat;
    //   this.form.lng = lng;
    // },
    initialPosition() {
      if (this.form.id) {
        this.form.lat = parseFloat(this.form.lat);
        this.form.lng = parseFloat(this.form.lng);
      } else {
        this.form.lat = 33.312805;
        this.form.lng = 44.361488;
      }
    },
    clearForm() {
      this.form = clearObject(this.form);
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss"></style>
